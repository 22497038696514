import React, { useState } from "react";

export function ShopProducts(props) {
  const [cart, setCart] = useState([]);

  const updateQuantity = (index, quantity) => {
    const updatedCart = [...cart];
    updatedCart[index].quantity += quantity;

    if (updatedCart[index].quantity <= 0) {
      updatedCart.splice(index, 1);
    }

    setCart(updatedCart);
  };

  const addToCart = (product) => {
    const updatedCart = [...cart];
    const existingItemIndex = updatedCart.findIndex(
      (item) => item.id === product.id
    );

    if (existingItemIndex !== -1) {
      updatedCart[existingItemIndex].quantity += 1;
    } else {
      updatedCart.push({ ...product, quantity: 1 });
    }

    setCart(updatedCart);
  };

  const calculateTotalAmount = () => {
    return cart.reduce((total, item) => total + item.price_in_cents * item.quantity, 0) / 100;
  };

  async function handleSubmitOrder(e) {
    e.preventDefault()
    const data = {
      user: props.user,
      products: [cart],
    }
    const response = await axios.post("/api/v1/user_menu_items", data)
    console.log(response.status)
    if(response.status === 200) {
      window.location.href = "/user_dashboard";
    } else {
      window.location.href = "/user_dashboard";
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-7">
          {props.shops.map((event_shop, shopIndex) => {
            console.log(event_shop.products);
            return (
              <>
                <h2>{event_shop?.shop?.name}</h2>
                {event_shop.products.map((product, productIndex) => {
                  return (
                    <div className="d-flex justify-content-between w-100" key={product.id}>
                      <p>{product.name}</p>
                      <div className="d-flex">
                        <p>
                          {(product.price_in_cents / 100).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </p>
                        <div>
                          <button onClick={() => addToCart(product)}>Adicionar</button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            );
          })}
        </div>
        <div className="col-md-5">
          <div>
            <h2>Carrinho</h2>
            {cart.map((cartItem, cartIndex) => (
              <div key={cartItem.id} className="d-flex justify-content-around align-items-center">
                <div>
                  <p>{cartItem.name}</p>
                </div>
                <div className="d-flex">
                  <p className="">
                    <span>
                      <i
                        className="fa fa-minus-circle fs-30 text-white clickable"
                        onClick={() => updateQuantity(cartIndex, -1)}
                      ></i>
                    </span>{" "}
                    <span className="px-3">{cartItem.quantity} </span>
                    <span>
                      <i
                        className="fa fa-plus-circle fs-30 text-white clickable"
                        onClick={() => updateQuantity(cartIndex, 1)}
                      ></i>
                    </span>
                  </p>
                  <p className="ms-3">
                    Total: R$ {(cartItem.price_in_cents * cartItem.quantity / 100).toFixed(2)}
                  </p>
                </div>
              </div>
            ))}
            <div className="">
              <button className="btn-close-order"
                onClick={(e) => handleSubmitOrder(e)}
              >
                Fechar pedido R$ {calculateTotalAmount().toFixed(2)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
